import React from "react";
import ReactDOM from "react-dom/client";
import { IconButton, Button, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, ListItem, List, ListItemText, Tooltip, Tabs, Tab, TextField, styled, Chip, CircularProgress, Alert, Snackbar, duration, createTheme, ThemeProvider, TableContainer, TableCell, TableBody, Table, TableRow, TableHead } from '@mui/material';
import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw";
import { useState, useEffect } from 'react';
import { createClient } from '@deepgram/sdk';
import Vapi from '@vapi-ai/web';
import axios from 'axios';
import io from 'socket.io-client';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import SyncIcon from '@mui/icons-material/Sync';
import StopIcon from '@mui/icons-material/Stop';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatIcon from '@mui/icons-material/Chat';
import Avatar from '@mui/material/Avatar';
import ReactGA from "react-ga4";
import * as Frigade from '@frigade/react';
import { useFlow } from '@frigade/react';
import ZoomVideo from '@zoom/videosdk';
const _ = require('lodash');


ReactGA.initialize([
  {
    trackingId: "G-KEJZXKN80Q",
  },
]);
ReactGA.send({ hitType: "pageview", page: "/", title: "hit" });
var client = ZoomVideo.createClient()
var stream

// TODO: move to process.env.DEEPGRAM_API_KEY
const deepgram = createClient('55dba888de36fd0a971cd6619beeb4ac9235c725');
var firstSilenceTimestamp = 0;
const vapi = new Vapi('c6a21d89-0ffd-45b2-9ad4-28a1fd2708f8')
var callId;
var socket;

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '3e10d61c-25cb-4da0-9f4c-f721d4fdf8ae' // prod
var env = String(process.env.REACT_APP_ENV)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5000'
  wsEndpoint = 'ws://localhost:5000'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
}

var stopInvoked = false;
const emptyInterviewObject = {
  state: 'none',
  messages: [],
  context: [],
  mute: false,
  metadata: {
    email_case_submissions: -1
  },
  scorecard: [],
  stage: ''
}
const StateToCueMap = {
  'none': 'Get started by clicking on the "Start Interview" button above.',
  "FirstResponse": "Let Steve know you can hear him ok. He's a bit hard of hearing, so you may have to tell him twice.",
  "ProblemAffirmation": "Tell Steve you want to proceed if you feel comfortable with the problem.",
  "DrawOnWhiteboard": 'Complete the diagram on the whiteboard to solve the given problem. You can talk to Steve if you get stuck. Click the "get whiteboard feedback" button to get whiteboard feedback.'
}

 // Override Material UI Theme Font
const customTheme = createTheme({
  typography: {
    fontFamily: 'Figtree, sans-serif',
  },
});

// TODO: SWITCH BACK, only for staging testing
var interviewDuration = 600;
const urlParams = new URLSearchParams(window.location.search);
const interviewTypeQueryParam = urlParams.get('interview_type');
if (interviewTypeQueryParam === 'residency' || interviewTypeQueryParam === 'phd_psych' || interviewTypeQueryParam === 'therapy') {
  interviewDuration = 600;
}

const App = () => {
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [interviewStarted, setInterviewStarted] = useState(false); 
    const [waitlistIsOpen, setWaitlistIsOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    // const [callId, setCallId] = useState(0)
    const [interview, setInterview] = useState(emptyInterviewObject);
    const [openHelp, setOpenHelp] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [loadingDiagramFeedback, setLoadingDiagramFeedback] = useState(false);
    const [whiteboardState, setWhiteboardState] = useState({});
    const [micEnabled, setMicEnabled] = useState(false);
    const [visualState, setVisualState] = useState('');
    const [firstMobileScreenDone, setFirstMobileScreenDone] = useState(false);
    const [scorecardModalOpen, setScorecardModalOpen] = useState(false);
    const [emailCaseDraft, setEmailCaseDraft] = useState('');
    const [emailSubmitPending, setEmailSubmitPending] = useState(false);
    const [tutorialStatus, setTutorialStatus] = useState('not_started');
    const [interviewStage, setInterviewStage] = useState('Introduction');
    const [emailCaseImage, setEmailCaseImage] = useState('');
    const [showWriteSection, setShowWriteSection] = useState(false);
    const [endTutorialModalOpen, setEndTutorialModalOpen] = useState(false);
    const [showWhiteboard, setShowWhiteboard] = useState(false)

    // Waitlist
    const [subscriptionSuccessSnackOpen, setSubscriptionSuccessSnackOpen] = React.useState(false);
    const [subscriptionFailSnackOpen, setSubscriptionFailSnackOpen] = React.useState(false);
    const [initialSubmissionDone, setInitialSubmissionDone] = useState(false);
    const [endTutorialModalOpenFirstBtnClicked, setEndTutorialModalOpenFirstBtnClicked] = useState(false);

    // Tabs
    const [tabValue, setTabValue] = React.useState(0);

    // Mobile home screen mode
    const [startMobileDemo, setStartMobileDemo] = React.useState(false);    

    // Material UI responsive layout settings
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const excalidrawStyle = {
        minHeight: '60vh',
        width: '100%',
        background: '#050505', // Change the background color
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // Timer
    const [timer, setTimer] = React.useState(interviewDuration);

    useEffect(() => {
      let interval = null;
      if (interviewStarted) {
        console.log('starting timer')
        interval = setTimeout(() => {
          if(timer > 0) {
            setTimer(timer => timer - 1)
          }
        }, 1000);
      }

      return () => {
          if (interval !== null && timer <= 0) {
              clearTimeout(interval);
          }
      };
    }, [interviewStarted, timer]);    
    
    const { flow } = useFlow("flow_rlk6j4gk")
    var currentStepIndex = 0
    if(flow !== undefined) {
      console.log(flow)
      currentStepIndex = flow.getCurrentStepIndex()
      if(tutorialStatus !== 'complete' && flow.isCompleted) {
        setTutorialStatus('complete')
      }
    }

    const handleOpenWaitlist = () => {
      setWaitlistIsOpen(true);
    }

    const handleCloseWaitlist = () => {
      setWaitlistIsOpen(false);
    }

    const handleCloseSuccessSnack = () => {
      setSubscriptionSuccessSnackOpen(false);
    }

    const handleCloseFailSnack = () => {
      setSubscriptionFailSnackOpen(false);
    }

    const handleEmailCaseSubmission = () => {
      console.log('handleEmailCaseSubmission')
      setEmailSubmitPending(true)
      setInitialSubmissionDone(true)

      const buffering_reses = [
        "Hang on, taking a look at your submission now.",
        "Let me review your submission real quick.",
        "I'm currently assessing the details of your submission.",
        "Give me a moment to go through your response.",
        "I'm diving into your submission as we speak.",
        "Examining your draft carefully, one moment please.",
        "Just opening your response to take a closer look.",
        "Allow me a few minutes to evaluate your response.",
        "Reviewing the contents of your email submission.",
        "Analyzing your submission, please hold."
      ];


      const buffering_res = buffering_reses[Math.floor(Math.random() * buffering_reses.length)];
      if(interview.metadata.email_case_submissions <= 3) {
        vapi.send({
          type: "add-message",
          message: {
            role: "system",
            content: "parrot_: " + buffering_res,
          },
        });        
      }

      axios.post(`${proxyEndpoint}/virtual_assistant_email_case`, {
        emailDraft: emailCaseDraft,
        callId: callId
      })
      .then((response) => {
        console.log('Email case submission success:', response.data);
        setEmailSubmitPending(false)
      })
      .catch((error) => {
        console.error('Email case submission error:', error);
        // Handle error scenario, e.g., showing an error message to the user
        setEmailSubmitPending(false)
      });
    }
    const validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }    

    const handleJoinWaitlist = async () => {
      const emailValid = validateEmail(email)
      if(!emailValid) {
        setSubscriptionFailSnackOpen(true)
        return
      }

      try {
        const response = await axios.post(proxyEndpoint + '/join_waitlist', {
          subscription: { email: email }
        });
        if(!response.data.result) {
          setSubscriptionFailSnackOpen(true);  
        } else {
          handleCloseWaitlist();
          setSubscriptionSuccessSnackOpen(true);
          setWaitlistIsOpen(false);
        }
      } catch (error) {
        setSubscriptionFailSnackOpen(true);
      }
      
    }

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    const handleCloseSubscriptionFailSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSubscriptionFailSnackOpen(false);
    };

    const handleCloseSubscriptionSuccessSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSubscriptionSuccessSnackOpen(false);
    };

    const handleChangeTab = (event, newValue) => {
      setTabValue(newValue);
    };


    // UI
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      fontFamily: 'figtree, sans-serif', // Set the font family to "figtree"
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginRight: '20px'
    }));

    // Help button
    const handleHelpOpen = () => {
      setOpenHelp(true);
    }
  
    const handleHelpClose = () => {
      setOpenHelp(false);
    };

    const handleStartMobileDemo = () => {
      setStartMobileDemo(true);
    }

    if(excalidrawAPI !== null && !updated) {
        fetch('prefill.excalidraw.json')
        .then(response => response.json())
        .then(data => {
          excalidrawAPI.updateScene({elements: data.elements, appState: data.appState});
          excalidrawAPI.scrollToContent(data.elements, {
              fitToContent: true,
              animate: false,
          });
          setUpdated(true);
          const appState = excalidrawAPI.getAppState();
          const elements = excalidrawAPI.getSceneElements();
          if (!elements || !elements.length) {
              return
          }          

          exportToBlob({
              elements,
              appState: {
                  ...appState,
                  exportWithDarkMode: false,
              },
              files: excalidrawAPI.getFiles(),
              maxWidthOrHeight: 1000
          })
          .catch((error) => {
              console.error('Error:', error);
          });
        })
    }

    const initWebsocketConnection = (callId) => {
      let clientType = 'desktop'
      if (isMobile) {
        clientType = 'mobile'
      }
      var interviewType = 'swe_with_english_proficiency'
      const urlParams = new URLSearchParams(window.location.search);
      const interviewTypeQueryParam = urlParams.get('interview_type');
      if (interviewTypeQueryParam) {
        interviewType = interviewTypeQueryParam;
      }

      console.log('initWebsocketConnection')
      const socket = io(wsEndpoint, {
        withCredentials: true,
        transports: ["websocket"]        
      });
      socket.on('connect', () => {
        console.log('Websocket connected');
      });
      socket.on('done', (data) => {
        if(data.callId === callId) {
          setTimeout(() => {
            handleStopInterview()
          }, 10000)
        }
      })

      socket.on('disconnect', () => {
        console.log('Websocket disconnected');
      });

      console.log(interviewType);

      socket.emit('register', {
        callId: callId,
        clientType: clientType,
        interviewType: interviewType,
        customer: 'surge',
      });
      
      socket.on('update', (interview) => {
        console.log('update', interview.metadata.callId, callId)
        console.log(interview)
        if(interview.metadata.callId === callId) {
          setInterview(interview);

          console.log(interview.stage.label, interviewType)

          if(interview.stage !== null && interview.stage.label !== null) {
            setInterviewStage(interview.stage.label)
          }

          if(interview.stage !== null && interview.stage.image !== null) {
            if( (interviewType === 'virtual_assistant' || interviewType === 'swe_with_english_proficiency') && interview.stage.label === 'Email Case Question') {
              setEmailCaseImage('email_case.jpg')
            } else if ( (interviewType === 'virtual_assistant' || interviewType === 'swe_with_english_proficiency') && interview.stage.label !== 'Email Case Question') {
              setEmailCaseImage('')
            } 
          }   
          
          if (interviewType === 'swe_with_english_proficiency' && interview.stage.label === 'System Design') {
            console.log('whiteboard', interview.stage.label, interviewType)
            setEmailCaseImage('')
            setShowWhiteboard(true)
          }          
  
          if(interview.stage === 'end') {
            setTimeout(() => {
              console.log('Interview has ended. Redirecting to scorecard...');
              navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                  stream.getTracks().forEach(track => track.stop());
                  console.log('User mic input turned off.');
                })
                .catch(error => console.error('Error turning off mic:', error));
              handleStopInterview()
            }, 15000);
          }
        }
      });

      socket.on('parrot', (res) => {
        console.log('par', res.callId)
        if(res.callId === callId) {
          console.log('parroting')
          vapi.send({
            type: "add-message",
            message: {
              role: "system",
              content: "parrot_: " + res.res,
            },
          });        
        }
      })

      return socket;
    }

    // const handleStartInterview_Sindarin = () => {
    //   const script = document.createElement("script");
    //   const apiKey = "d935082f-b4d0-4c99-a496-c69bf1b5c249";      
    //   script.src = "https://api.sindarin.tech/PersonaClientPublic?apikey=" + apiKey;      
    //   const personaName = ""
    //   const userId = ""

    //   script.addEventListener("load", async () => {
    //     console.log("persona client loaded");
    //     const personaClient = new window.PersonaClient(apiKey);      
    //     personaClient.init(userId, personaName)      
        
    //   })
    // }

    const handleStartTutorial = () => {
      console.log('handleStartTutorial')
      setTutorialStatus('started');
      setInterviewStage("Introduction")
      setInterview({ ...interview, context: ['These are sample instructions.']})
      setEmailCaseImage('tutorial_case.jpeg')
    }

    const handleStartInterview = () => {
        console.log('handleStartInterview')
        setInterviewStarted(true);
        vapi.start(vapiEnvId)
        .then((data) => {
          // i assume ID is callId
          callId = data['id']
          if(socket === undefined) {
              socket = initWebsocketConnection(callId);

              // get JWT from server
              fetch(proxyEndpoint + '/get_video_jwt', {
                body: JSON.stringify({
                  callId: callId
                }), 
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then((data) => {
                data.json().then((jsonData) => {
                  console.log('jwt json res', jsonData)

                  client.init('en-US', 'Global', { patchJsMedia: true }).then(() => {
                    client
                      .join(callId, jsonData.jwt, 'candidate')
                      .then(() => {
                        stream = client.getMediaStream()
                        stream.startVideo()
                        const recordingClient = client.getRecordingClient()
                        recordingClient.startCloudRecording()
                        .then(() => {
                          console.log('recording')
                        })
                        .catch((err) => {
                          console.error(err)
                        })
                      })
                  })                                  
                })
              })
              .catch((err) => {
                console.error(err)
              })          
          }                  
        })
        .catch((err) => {console.error(err)})

        vapi.on('speech-start', () => {
            console.log('Speech has started');
            setVisualState('speaking');
        });
        
        vapi.on('speech-end', () => {
            console.log('Speech has ended');
            setVisualState('listening');
        });
        
        vapi.on('call-start', () => {
            console.log('Call has started');            
        });
        
        vapi.on('call-end', () => {
            console.log('Call has stopped');
        });
        
        vapi.on('volume-level', (volume) => {
        });
        
        // Function calls and transcripts will be sent via messages
        vapi.on('message', (message) => {
            console.log(message);
        });
        
        vapi.on('error', (e) => {
            console.error(e)
        });   
    }

    const handleStopInterview = () => {
      console.log('handleStopInterview')
      if(!scorecardModalOpen) {
        const recordingClient = client.getRecordingClient()
        recordingClient.stopCloudRecording()
        .then(() => {
          console.log('stopped')
          stream.stopVideo()
        })        
        .catch((err) => {
          console.error(err)
        })

        vapi.stop()
        // get scorecard
        if(stopInvoked) {
          return 
        }
        stopInvoked = true

        if(interviewType !== 'patient_questionnaire' && interviewType !== 'virtual_assistant_tutorial') {
          console.log('making request')
          axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })
          .then((response) => {
              console.log(response.data)
          })
          .catch((error) => {          
              console.error('Error fetching scorecard:', error);
          });
  
          setScorecardModalOpen(true);        
        }

        if(interviewType === 'virtual_assistant_tutorial') {
          setEndTutorialModalOpen(true)
        }        

        // if(socket != null) {
        //     socket.close();
        //     socket = null;
        // }
        setInterview(emptyInterviewObject)
      }
    }

    const handleSubmitDiagramFeedback = () => {
      console.log('handleSubmitDiagramFeedback')
      setLoadingDiagramFeedback(true);
      const buffering_reses = [
        'Hang on, taking a look at the whiteboard', 
        'One sec, taking a look', 
        'Looking, give me one second.',
        "Bear with me, I'm perusing the whiteboard details.",
        "Just a tick, I'm eyeing the whiteboard content.",
        'Allow me a brief pause to scan the whiteboard.',
        'I need a short moment to digest the whiteboard info.',
        'Let me take a swift glance at the whiteboard.',
        "I'm diving into the whiteboard, hold on.",
        'A quick study of the whiteboard is underway.',
        "I'm zooming in on the whiteboard, stand by.",
        'Peering into the whiteboard, just a moment.',
        "I'm deciphering the whiteboard scribbles, hang tight."
      ];
      const buffering_res = buffering_reses[Math.floor(Math.random() * buffering_reses.length)];
      vapi.send({
        type: "add-message",
        message: {
          role: "system",
          content: "parrot_: " + buffering_res,
        },
      });        
      
      if (!excalidrawAPI) {
          return
      }
      const elements = excalidrawAPI.getSceneElements();
      if (!elements || !elements.length) {
          return
      }

      const appState = excalidrawAPI.getAppState();

      exportToBlob({
          elements,
          appState: {
              ...appState,
              exportWithDarkMode: false,
          },
          files: excalidrawAPI.getFiles(),
          maxWidthOrHeight: 1000
      })
      .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
              const base64data = reader.result;
              fetch(`${proxyEndpoint}/get_diagram_feedback`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ image: base64data, callId: callId })
              })
              .then((response) => {
                  setLoadingDiagramFeedback(false);
                  response.json()
                  .catch((err) => {console.error(err)})
              })
              .catch((err) => {console.error(err)})
          }
      }) 
    }

    if (timer <= 0) {
        handleStopInterview()
    }

    const renderFeedback = () => {
      if( initialSubmissionDone && !emailSubmitPending && interview.context.length > 1 && interview.context[interview.context.length-1].startsWith('Feedback:') ) {
        return (
          <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
            <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', fontWeight: 700 }}>
              {interview.context[interview.context.length-1].replace('Feedback:', '')}
            </Typography>
          </div>                                        
        )
      } else {
        return (
          <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
            <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', fontWeight: 700 }}>
              N/A
            </Typography>
          </div>                                        
        )
      }
    }

    const renderContext = () => {
      if(interviewType === 'virtual_assistant_tutorial' && interviewStarted){
        if(interview.messages.length <= 3) {
          return (
            <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>
                Respond to Steve!
              </Typography>
            </div>            
          )
        } else if (interview.messages.length > 3 && !showWriteSection) {
          // setTimeout(() => {
          //   setShowWriteSection(true)
          // }, 30000)
          setShowWriteSection(true)
          return (
            <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>
                Nice job! Now, read the text on the image that just popped up on the screen. Respond to Steve with what you think.
              </Typography>
            </div>            
          ) 
        } else if (interview.messages.length > 6 && !initialSubmissionDone && showWriteSection) {
          return (
            <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>
                Submit your response in the text input and click "Submit". 
              </Typography>
            </div>            
          ) 
        } else if(initialSubmissionDone){
          return (
            <>
              <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
                <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
                <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>
                  Revise your response based on Steve's feedback. Click "Re-Submit" when you're done.
                </Typography>
              </div>            
            </>
          )           
        }
      } else {
        console.log('render context')
        if(interview.stage.label === 'Email Case Question' && !showWriteSection) {
          console.log('show write section')
          // setTimeout(() => {
          //   setShowWriteSection(true)
          // }, 60000)
          setShowWriteSection(true)
        }

        var context = []
        interview.context.map((message, index) => {
          console.log(showWriteSection, index, interview.context, interview.context[index+1])
          if(showWriteSection && !showWhiteboard && index + 1 <= interview.context.length -1 && interview.context[index +1].startsWith('Feedback')) {
            context.push(message)
            context.push('Click "Submit" once you are happy with your email draft to get feedback on your submission in order to improve it.')
          } else if((showWriteSection || showWhiteboard) && index == interview.context.length - 1 && message.startsWith('Feedback')) {
            console.log('do not push anything')
          } else {
            context.push(message)
          }
        })

        return context.map((message, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>{message}</Typography>
          </div>
        ))
      }      
    }

    const renderModalTitle = () => {
      if(interviewType === 'virtual_assistant_tutorial') {
        return 'Start Tutorial'
      } else {
        return 'Start Interview'
      }
    }

    const renderIntroDialoguePostInputsEnabledContent = () => {
      if(interviewType === 'virtual_assistant_tutorial' && tutorialStatus === 'not_started') { 
        return (
          <>
            {!micEnabled &&
              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                This is a tutorial for using Interviewer. Please enable your microphone and camera to talk to Steve, your AI interviewer.
              </div>
            } 
            {micEnabled &&
              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                Your microphone and camera is enabled! Press "Start Tutorial" to learn how the product works and go through a demo experience before your real interview.
              </div>                            
            }          
          </>         
        )
      } else if(interviewType === 'virtual_assistant_tutorial' && tutorialStatus === 'complete') {
        return (
          <>
            <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
              We will now do a <b>practice interview</b> experience which will be the last part of the tutorial. Once you have finished this, we will move onto the real interview when you are ready.
            </div>                                         
          </>
        )
      } else {
        return (
          <>
          {!micEnabled &&
            <div style={{ color: 'white', fontFamily: 'Muli`sh', padding: 15 }}>
              You will be doing a 10 minute pre-screening interview. Please enable your microphone and camera to talk to Steve, your AI interviewer.
            </div>
          } 
          {micEnabled &&
            <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
              Your microphone and camera is enabled! Press the start interview button and our interviewer Steve will read you off a prompt and instructions.
            </div>                            
          }           
        </>         
        )   
      }
    }    

    function secondsToHumanReadableTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    
      return formattedTime;
    }    

    const getDisplayValue = (idSelector) => {
      if(interviewType !== 'virtual_assistant_tutorial') {
        return 'visible'
      }

      if(tutorialStatus === 'complete') {
        return 'visible'
      }

      switch(idSelector) {
        case 'stage':
          if(currentStepIndex >= 0) {
            return 'visible'
          } else {
            return 'hidden'
          }
        case "instructions":
          if(currentStepIndex >= 1) {
            return 'visible'
          } else {
            return 'hidden'
          }   
        case 'mute-steve':
          if(currentStepIndex >= 2) {
            return 'visible'
          } else {
            return 'hidden'
          }
        case 'email-case-image':
          if(currentStepIndex >= 3) {
            return 'visible'
          } else {
            return 'hidden'
          }
        case 'submission':
          if(currentStepIndex >= 4) {
            return 'visible'
          } else {
            return 'hidden'
          }
        case "feedback":
          if(currentStepIndex >= 5) {
            return 'visible'
          } else {
            return 'hidden'
          }                 
      }
    }

    const handleToggleMute = () => {
      console.log('handleToggleMute')
      socket.emit('mute', callId);
    }

    const enableMic = () => {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then(function(stream) {
        setMicEnabled(true);
      })
      .catch(function(err) {
        console.log('Microphone permission denied');
      });
    }
    console.log('(isMobile && firstMobileScreenDone) || !isMobile', (isMobile && firstMobileScreenDone) || !isMobile)
    var interviewType = 'swe_with_english_proficiency'
    const urlParams = new URLSearchParams(window.location.search);
    const interviewTypeQueryParam = urlParams.get('interview_type');
    if (interviewTypeQueryParam) {
      interviewType = interviewTypeQueryParam;
    }

    return (
        <>
          <Container>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12}>
                <Dialog open={waitlistIsOpen} onClose={handleCloseWaitlist}>
                  <DialogTitle
                    style={{
                      background: 'black',
                      color: 'white',
                      border: '0.5px solid #5F6368',
                      fontFamily: 'Mulish'
                    }}
                  >Waitlist</DialogTitle>
                  <DialogContent
                    style={{
                      background: 'black',
                      color: 'white',
                      border: '0.5px solid #5F6368',
                      padding: 10,
                      fontFamily: 'Mulish'
                    }}
                  >
                    <DialogContentText style={{
                      fontFamily: 'Mulish',
                      color: 'white',
                      padding: 10
                    }}>
                      <TextField
                          InputProps={{
                            style: {
                              fontFamily: 'Mulish',
                              color: 'white',
                              marginTop: 15,
                            },
                          }}
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Email Address"
                          type="email"
                          fullWidth
                          onChange={handleEmailChange}
                          variant="standard"
                        />                      
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions 
                    style={{
                      background: 'black',
                      color: 'white',
                      border: '0.5px solid #5F6368',
                      fontFamily: 'Mulish'
                    }}
                  >
                    <Button 
                      style={{ color: 'black', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}                    
                      onClick={handleCloseWaitlist}>Cancel</Button>
                    <Button 
                      style={{ color: 'black', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}                    
                      onClick={handleJoinWaitlist}>Subscribe</Button>
                  </DialogActions>
                </Dialog>
                <Snackbar
                  open={subscriptionSuccessSnackOpen}
                  autoHideDuration={5000}
                  onClose={handleCloseSubscriptionSuccessSnack}>
                    <Alert onClose={handleCloseSuccessSnack} severity="success" sx={{ width: '100%' }}>
                      You're on the waitlist!
                    </Alert>
                  </Snackbar>
                  <Snackbar
                  open={subscriptionFailSnackOpen}
                  autoHideDuration={5000}
                  onClose={handleCloseSubscriptionFailSnack}>
                    <Alert onClose={handleCloseFailSnack} severity="error" sx={{ width: '100%' }}>
                      Error: e-mail already used or invalid format.
                    </Alert>
                  </Snackbar>
              </Grid>
            </Grid>
          </Container>
          {/* {((isMobile && firstMobileScreenDone) || !isMobile) &&
          <Paper elevation={0} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', zIndex: 9999, background: '#19e539', color: 'black' }}>
            <Typography variant="h6" style={{ cursor: 'pointer', textTransform: 'none' }}>
              <Button onClick={handleOpenWaitlist} style={{ color: 'black', textTransform: 'none', fontSize: 16, fontFamily: 'Mulish' }}>
                {isMobile &&
                  <>
                  Get future access.
                  </>  
                }
                {!isMobile &&
                  <>
                    Get future access and your interview recording.
                  </>
                }
                <span style={{ textDecoration: 'underline', marginLeft: '4px' }}>Join waitlist.</span>
              </Button>
            </Typography>
          </Paper>                        
          } */}
          <Container maxWidth = "xl" className = "whiteboardView" style={{ backgroundColor: '#050505' }}>       
            <div style={{ marginTop: 25 }}>
            <a href="https://techinterviewer.ai">
              <svg width="100%" viewBox="0 0 118 42" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="svg-logo-white w-embed">
                <g clip-path="url(#clip0_4959_53640)">
                <path d="M49.7965 20.7307C49.46 20.5419 49.1023 20.3935 48.731 20.2885C48.3331 20.1537 47.5594 19.9415 46.3613 19.6475C45.6891 19.5176 45.0249 19.3493 44.3719 19.1435C43.5451 18.823 43.1981 18.3963 43.1981 17.6779C43.1981 16.4268 44.2635 15.6265 45.8618 15.6265C47.5395 15.6265 48.899 16.4268 49.644 17.8901L51.3483 16.5329C50.495 14.802 48.3928 13.6592 45.9679 13.6592C44.478 13.6592 43.2533 14.0586 42.294 14.8573C41.835 15.2108 41.4656 15.6673 41.2156 16.1899C40.9656 16.7125 40.842 17.2866 40.8549 17.8658C40.8549 19.1457 41.297 20.0498 42.347 20.6621C42.6913 20.8776 43.0573 21.0561 43.439 21.1949C43.8657 21.3297 44.5575 21.5154 45.5434 21.7276C46.368 21.9155 46.9272 22.0216 47.1682 22.1012C47.3941 22.1442 47.6159 22.2063 47.8313 22.2869C48.7885 22.6074 49.1577 23.1136 49.1577 23.9381C49.1577 25.3241 47.933 26.1487 46.0961 26.1487C45.2112 26.1624 44.3408 25.9243 43.5861 25.462C42.8315 24.9998 42.2239 24.3325 41.8342 23.538L39.9707 24.8975C40.9566 26.9223 43.3528 28.147 46.0961 28.147C47.6434 28.147 48.9189 27.7203 49.9579 26.8936C50.448 26.5257 50.8439 26.0467 51.1129 25.496C51.3819 24.9454 51.5163 24.3387 51.5052 23.7259C51.5052 22.3554 50.9681 21.3982 49.7965 20.7307Z" fill="currentColor"></path>
                <path d="M89.7178 15.9646C88.9441 14.5521 87.2862 13.6479 85.4559 13.6479C83.6256 13.6479 82.0473 14.3664 80.9553 15.7524C79.9694 17.0035 79.4079 18.6813 79.4079 20.5713C79.3721 22.3141 79.9164 24.0193 80.9553 25.419C81.4937 26.0916 82.1797 26.6312 82.9602 26.9961C83.7406 27.361 84.5946 27.5415 85.4559 27.5234C87.2929 27.5234 88.9441 26.6171 89.7178 25.2046V26.7763C89.7178 29.4665 88.3053 30.9321 85.6416 30.9321C83.6985 30.9321 82.2595 29.8931 81.8063 28.1092L79.5781 28.9581C80.3253 31.4891 82.5623 32.937 85.6504 32.937C87.6222 32.937 89.1873 32.3777 90.3655 31.2327C91.5437 30.0876 92.1229 28.5425 92.1229 26.5906V13.9132H89.7178V15.9646ZM89.7443 20.8653C89.7443 23.6351 88.1461 25.419 85.8825 25.419C83.4045 25.419 81.8063 23.5268 81.8063 20.5713C81.8063 17.6158 83.3537 15.7524 85.8825 15.7524C88.1461 15.7524 89.7443 17.5628 89.7443 20.3591V20.8653Z" fill="currentColor"></path>
                <path d="M107.771 20.4655C107.771 18.4141 107.238 16.7628 106.173 15.5117C105.107 14.2605 103.668 13.6482 101.831 13.6482C100.928 13.6339 100.034 13.8228 99.2132 14.2008C98.3929 14.5788 97.668 15.1364 97.0921 15.8322C96.0001 17.1364 95.4121 18.9468 95.4121 20.8921C95.4121 22.9147 96.0266 24.7008 97.1164 25.9763C98.2902 27.3866 99.9901 28.1338 101.938 28.1338C104.681 28.1338 106.812 26.6682 107.663 24.1394L105.561 23.3922C105.134 25.07 103.775 26.0448 101.938 26.0448C99.4883 26.0448 97.8371 24.1548 97.7044 21.2502H107.771V20.4655ZM97.837 19.4265C98.2637 17.0834 99.7536 15.6974 101.805 15.6974C103.962 15.6974 105.267 17.163 105.342 19.4265H97.837Z" fill="currentColor"></path>
                <path d="M70.0059 13.9131V16.0286V27.8416H72.4021V16.0286H77.8974V13.9131H72.4021H70.0059Z" fill="currentColor"></path>
                <path d="M113.316 8.66992H114.09L116.112 13.9133H115.403L114.87 12.5339H112.516L111.992 13.9133H111.289L113.316 8.66992ZM113.687 9.45908L112.752 11.9194H114.636L113.687 9.45908Z" fill="currentColor"></path>
                <path d="M117.509 8.66992V13.9133H116.809V8.66992H117.509Z" fill="currentColor"></path>
                <path d="M63.3754 13.9131V22.7287C63.3754 23.576 63.0389 24.3887 62.44 24.9881C61.841 25.5875 61.0285 25.9245 60.1812 25.9251C59.3334 25.9251 58.5204 25.5883 57.921 24.9889C57.3215 24.3894 56.9848 23.5764 56.9848 22.7287V13.9131H54.5996V22.5341C54.5996 24.0174 55.1888 25.4399 56.2377 26.4887C57.2865 27.5376 58.709 28.1268 60.1922 28.1268C61.6755 28.1268 63.098 27.5376 64.1468 26.4887C65.1956 25.4399 65.7849 24.0174 65.7849 22.5341V13.9131H63.3754Z" fill="currentColor"></path>
                <path d="M28.7021 21.9548C27.8936 20.5549 26.6778 19.4345 25.2167 18.7426C23.7556 18.0508 22.1185 17.8203 20.5232 18.082L25.7842 15.0447C26.6899 14.5222 27.4838 13.8264 28.1207 12.997C28.7575 12.1677 29.2247 11.221 29.4956 10.2111C29.7666 9.20121 29.836 8.14782 29.6998 7.1111C29.5637 6.07438 29.2246 5.07462 28.7021 4.16891C28.1796 3.26321 27.4838 2.46929 26.6545 1.83249C25.8251 1.19568 24.8785 0.728468 23.8685 0.457516C22.8586 0.186565 21.8052 0.117184 20.7685 0.253334C19.7318 0.389483 18.732 0.728497 17.8263 1.25102L4.03044 9.20891C2.41047 10.136 1.16795 11.6031 0.520107 13.3536C-0.127732 15.1041 -0.139658 17.0265 0.486414 18.7849C1.11249 20.5433 2.33672 22.0257 3.94505 22.9728C5.55339 23.92 7.44348 24.2717 9.28486 23.9664L4.0238 27.0037C3.11737 27.5258 2.32277 28.2214 1.68545 29.0509C1.04812 29.8804 0.580583 30.8274 0.309575 31.8377C0.0385665 32.848 -0.0305946 33.9019 0.106048 34.9389C0.242691 35.976 0.582458 36.976 1.10591 37.8816C1.6282 38.7879 2.32402 39.5823 3.15357 40.2194C3.98313 40.8565 4.93015 41.3238 5.94046 41.5946C6.95077 41.8654 8.00456 41.9344 9.04155 41.7976C10.0785 41.6608 11.0784 41.3209 11.9839 40.7973L25.7776 32.8394C26.6838 32.3172 27.4782 31.6213 28.1153 30.7918C28.7524 29.9622 29.2198 29.0152 29.4906 28.0049C29.7614 26.9946 29.8303 25.9408 29.6935 24.9038C29.5567 23.8668 29.2168 22.867 28.6933 21.9614L28.7021 21.9548Z" fill="currentColor"></path>
                </g>
                <defs>
                <clipPath id="clip0_4959_53640">
                <rect width="117.6" height="42" fill="currentColor"></rect>
                </clipPath>
                </defs>
              </svg>
            </a>
            </div>              
            <br/>
              <Grid container direction="column" spacing={3}>                  
                  <Grid item container xs={12} style={{ marginBottom: 15 }}>
                    {!isMobile &&
                    <Grid item xs={4} style={{ textAlign: 'left' }}>
                      <div style={{fontSize: 24, fontFamily: 'Mulish'}}>
                        {interviewType === 'patient_questionnaire' &&
                          <>
                          Lupus Patient Check-in
                          </>
                        }
                        {interviewType === 'system_design' &&
                          <>
                            Mock System Design Interview
                          </>
                        }
                        {interviewType === 'virtual_assistant' &&
                          <>
                            Virtual Assistant Pre-Screening Interview
                          </>
                        }
                        {interviewType === 'instacart_biz_ops' &&
                          <>
                            Instacart Business Operations Manager Pre-Screening Interview
                          </>
                        }     
                        {interviewType === 'residency' &&
                          <>
                            Mock Residency Interview
                          </>
                        }                          
                        {interviewType === 'phd_psych' &&
                          <>
                            Mock Clinical Psych PhD Interview
                          </>
                        }    
                        {interviewType === 'therapy' &&
                          <>
                            Therapy Session
                          </>
                        }  
                        {interviewType === 'virtual_assistant_tutorial' && 
                          <>
                          Virtual Assistant Tutorial
                          </>
                        }                                                                                                                  
                        {interviewType === 'swe_with_english_proficiency' &&
                          <>
                            Software Engineer Labeler Pre-Screening Interview
                          </>
                        }                                                
                      </div>
                    </Grid>                    
                    }
                    {(firstMobileScreenDone && isMobile) &&
                    <>
                    <Grid item xs={12} style={{ textAlign: 'left', marginBottom: 30, marginTop: 30 }}>
                      <div style={{fontSize: 17, fontFamily: 'Mulish' }}>
                        {interviewType === 'system_design' &&
                          <>
                            Mock System Design Interview
                          </>
                        }
                        {interviewType === 'virtual_assistant' &&
                          <>
                            Virtual Assistant Pre-Screening Interview
                          </>
                        }
                        {interviewType === 'swe_with_english_proficiency' &&
                          <>
                            Software Engineer Labeler Pre-Screening Interview
                          </>
                        }                        
                        {interviewType === 'instacart_biz_ops' &&
                          <>
                            Instacart Business Operations Manager Pre-Screening Interview
                          </>
                        }              
                        {interviewType === 'residency' &&
                          <>
                            Mock Residency Interview
                          </>
                        }                                                     
                        {interviewType === 'phd_psych' &&
                          <>
                            Mock Clinical Psych PhD Interview
                          </>
                        }    
                        {interviewType === 'therapy' &&
                          <>
                            Therapy Session
                          </>
                        }            
                        {interviewType === 'virtual_assistant_tutorial' && 
                          <>
                          Virtual Assistant Tutorial
                          </>
                        }                                                        
                      </div>
                    </Grid>                         
                    </>
                    }
                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <div> 
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        {!isMobile && 
                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 30, fontSize: 16, fontFamily: 'Mulish', visibility: interviewStarted ? 'visible' : 'hidden', color: '#4451f6', marginBottom: 0, paddingBottom: 0 }}>
                            {visualState === '' &&
                              <div style={{ color: interviewStarted ? 'white' : 'black' }}>
                                Steve is Connecting...
                              </div>
                            }
                            {visualState === 'listening' &&
                              <div>
                                Steve is <b>Listening</b>
                              </div>
                            }
                            {visualState === 'speaking' &&
                              <div>
                                Steve is <b>Speaking</b>
                              </div>
                            }                          
                          </div>
                        }
                          {/* {((isMobile && firstMobileScreenDone) || !isMobile) &&
                          <div style={{ display: 'flex', alignItems: isMobile ? 'left' : 'center', fontFamily: 'Mulish', fontSize: 18, color: 'white', marginRight: isMobile ? 0 : 15, position: isMobile ? 'absolute' : 'relative', left: isMobile ? '2.5vh' : 0 }}>
                            <AccessTimeIcon />
                            <span style={{ fontSize: 18, marginLeft: 5 }}>
                              {secondsToHumanReadableTime(timer)}
                            </span>
                          </div>                          
                          } */}
                          {interviewStarted &&
                            <IconButton onClick={handleStopInterview} style={{
                              background: '#4451f6',
                              color: 'white',
                              borderRadius: 25,
                              fontFamily: 'Mulish',
                              fontSize: 16,
                              marginLeft: 15, // Added to provide spacing between buttons
                            }}>
                              <StopIcon /> Stop/ Score
                            </IconButton>                          
                          }
                          {!isMobile && ((interviewType.startsWith('system_design') || interviewType === 'swe_with_english_proficiency') && showWhiteboard) &&
                            <IconButton onClick={handleSubmitDiagramFeedback} disabled={loadingDiagramFeedback} style={{
                              background: '#4451f6',
                              color: 'black',
                              borderRadius: 25,
                              fontFamily: 'Mulish',
                              fontSize: 16,
                              marginLeft: 15, // Added to move the button to the right
                            }}>
                              {loadingDiagramFeedback ? 'Syncing...' : <><SyncIcon />
                              Sync</>}                          
                            </IconButton>
                          }
                          {((isMobile && firstMobileScreenDone) || !isMobile) &&
                            <IconButton id="mute-steve" onClick={handleToggleMute} style={{
                              background: interview.mute ? 'white' : '#181818',
                              fontSize: 16,
                              color: interview.mute ? 'black' : 'white',
                              borderRadius: 25,
                              fontFamily: 'Mulish',
                              position: isMobile ? 'absolute' : 'relative',
                              right: isMobile ? '2.5vh' : 0,
                              marginLeft: 15, // Added to provide spacing between buttons
                              padding: 10,
                              visibility: getDisplayValue('mute-steve')
                            }}
                            disabled={callId === undefined}>
                              {interview.mute ? (<>
                              {!isMobile &&
                                <>Un-mute Steve</>
                              }
                              </>) : <>
                                {!isMobile &&
                                  <>Steve is interrupting me!</>
                                }
                              </>} 
                            </IconButton>                          
                          }
                        </div>
                      </div>     
                    </Grid>
                  </Grid>
                  {isMobile &&
                  <Grid item xs={12} sm={12}>
                    <div style={{ textAlign: 'left', alignItems: 'left', fontSize: 16, fontFamily: 'Mulish', color: '#181818' }}>
                      {visualState === '' &&
                        <div style={{ color: interviewStarted ? '#181818' : 'black' }}>
                          Steve is Connecting...
                        </div>
                      }
                      {visualState === 'listening' &&
                        <div>
                          Steve is <b>Listening</b>
                        </div>
                      }
                      {visualState === 'speaking' &&
                        <div>
                          Steve is <b>Speaking</b>
                        </div>
                      }                          
                    </div>  
                  </Grid>                      
                  }  
                  <Grid item xs={12} style={{ fontFamily: 'Mulish', fontSize: 20, marginTop: 0, paddingTop: 0 }}>
                    <span id="stage" style={{ visibility: getDisplayValue('stage'), color: 'gray' }}>
                      Stage: {interviewStage}
                    </span>
                  </Grid>                   
                  {tutorialStatus === 'started' &&                   
                    <Frigade.Tour
                      flowId="flow_rlk6j4gk" 
                    />                 
                  }                                  
                  {!isMobile &&
                    <Grid item container style={{ borderRadius: 10, paddingRight: 25, visibility: getDisplayValue('instructions'), paddingTop: 0, marginTop: 0 }}>
                    <>
                      <Grid item xs={(emailCaseImage === '' && !showWhiteboard) ? 12 : 3} style={{ visibility: getDisplayValue('instructions') }}>
                        <div id="instructions" style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Instructions</p>
                        </div>
                        <br/>
                        {renderContext()}
                        {(initialSubmissionDone || tutorialStatus === 'started') &&
                          <div id="feedback" style={{ visibility: getDisplayValue('feedback'), background: '#4451f6', paddingLeft: 10, paddingRight: 10, borderRadius: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                              <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Feedback</p>
                            </div>
                            <br/>
                            {renderFeedback()}
                            <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25, maxHeight: 75, height: 75, overflow: 'auto' }}>
                              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', fontWeight: 700, paddingBottom: 0, marginBottom: 0 }}>
                                Edit and hit the Re-Submit button
                              </Typography>
                            </div>                                                                                            
                            {(loadingDiagramFeedback || emailSubmitPending) && (
                                <div style={{ color: 'white', fontFamily: 'Mulish', display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                                  <CircularProgress size={20} style={{ marginRight: 10 }} /> Updating Feedback...
                                </div>
                              )
                            }                                                                
                          </div>
                        }
                      </Grid>                                        

                    {(emailCaseImage !== '' && ( (interviewType === 'virtual_assistant_tutorial' && interviewStarted && interview.messages.length > 3) || (tutorialStatus === 'started' ) || (interviewType === 'virtual_assistant' || interviewType === 'swe_with_english_proficiency') )) &&
                    <>
                      <Grid item xs={9} style={{ paddingLeft: 30 }}>
                        <img 
                          id="email-case-image"
                          style={{
                            width: '80%', // Adjusted to control the maximum width
                            maxWidth: '80%',
                            visibility: getDisplayValue('email-case-image'),
                            position: 'relative',
                          }}
                          src={emailCaseImage} 
                        />
                      {(tutorialStatus === 'started' || (interview.messages.length > 6 && showWriteSection)) &&
                        <>
                            <br/><br/>
                            <textarea 
                              id='submission'
                              style={{
                                fontFamily: 'Mulish',
                                fontSize: 16,
                                border: '0.5px solid #5F6368',
                                borderRadius: 10,
                                background: 'black',
                                color: 'white',
                                padding: 15, 
                                width: '50%',
                                height: 150,
                                visibility: getDisplayValue('submission'),
                              }}
                              disabled={emailCaseImage === ''}
                              value={emailCaseDraft}
                              onChange={(e) => {setEmailCaseDraft(e.target.value)}}
                              placeholder="Enter email draft here and then click Submit for feedback"
                            />
                            <br/><br/>
                            <Button variant={'contained'} onClick={handleEmailCaseSubmission} 
                              style={{
                                background: '#4451f6',
                                color: 'white',
                                borderRadius: 35,
                                fontFamily: 'Mulish',
                                fontSize: 16,
                                marginRight: 25, // Added to provide spacing between buttons
                                opacity: emailSubmitPending ? 0.5 : 1,
                                visibility: getDisplayValue('submission'),
                                width: 120,
                                textAlign: 'center', 
                              }}
                              disabled={tutorialStatus === 'started' || emailSubmitPending || emailCaseImage === ''}
                            >
                              {emailSubmitPending ? <CircularProgress size={20} style={{ color: 'black', marginRight: 10 }} /> : null}
                              {emailSubmitPending ? <>Looking</> : <>{initialSubmissionDone ? 'Re-Submit' : 'Submit'}</>}
                            </Button>                                                  
                        </>  
                      }
                      </Grid>
                    </>
                    }
                    {(showWhiteboard && emailCaseImage === '') &&
                      <Grid item xs={9}>
                        <div className="excalidraw-custom">
                          <Excalidraw 
                            theme="dark"
                            excalidrawAPI={(api)=> setExcalidrawAPI(api)}                       
                          />   
                        </div>
                      </Grid>
                    }
                    </>
                  </Grid>
                  }                     
                  {((!isMobile && !interviewStarted && (tutorialStatus === 'not_started' || tutorialStatus === 'complete')) || (isMobile && firstMobileScreenDone && !interviewStarted || (tutorialStatus === 'not_started' || tutorialStatus === 'complete'))) && 
                    <Dialog open={!interviewStarted}>
                      <DialogTitle style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'
                      }}>{!micEnabled ? "Get Started" : renderModalTitle()}</DialogTitle>
                      <DialogContent style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        padding: 10
                      }}>
                        <DialogContentText>
                          {renderIntroDialoguePostInputsEnabledContent()}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions style={{ background: 'black', border: '0.5px solid #5F6368', padding: 10 }}>
                        {!micEnabled &&
                          <Button autoFocus onClick={enableMic} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                            {micEnabled ? 'Mic and Camera Enabled' : 'Enable Mic and Camera'}
                          </Button>                        
                        }
                        {micEnabled &&
                          <Button onClick={() => {interviewTypeQueryParam === 'virtual_assistant_tutorial' && tutorialStatus === 'not_started' ? handleStartTutorial() : handleStartInterview() }} disabled={!micEnabled} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                            {interviewTypeQueryParam === 'virtual_assistant_tutorial' && tutorialStatus === 'not_started' ? 'Start Tutorial' : 'Start Interview'}
                          </Button>                        
                        }
                      </DialogActions>
                    </Dialog>
                  }
                  {endTutorialModalOpen && 
                    <Dialog open={endTutorialModalOpen}>
                      <DialogTitle style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'
                      }}>Go to the Real Interview</DialogTitle>
                      <DialogContent style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        padding: 10
                      }}>
                        <DialogContentText>
                          {!endTutorialModalOpenFirstBtnClicked &&
                            <p style={{ color: 'white' }}>
                              Thanks for taking the practice interview! 
                              <br/><br/>
                              The real interview will ask you about your background and have a case study that involves analyzing and responding to a situation. You will be graded on your communication skills, reading ability, your written response and how you respond to feedback.                            
                            </p>
                          }
                          {endTutorialModalOpenFirstBtnClicked &&
                            <p style={{ color: 'white' }}>
                              Click "Go" when you are ready to start the real interview.
                            </p>
                          }
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions style={{ background: 'black', border: '0.5px solid #5F6368', padding: 10 }}>
                        {!endTutorialModalOpenFirstBtnClicked &&
                          <Button autoFocus onClick={() => {setEndTutorialModalOpenFirstBtnClicked(true)}} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                            I understand
                          </Button>
                        }
                        {endTutorialModalOpenFirstBtnClicked &&
                          <Button autoFocus onClick={() => {window.location.href = `${window.location.origin}?interview_type=virtual_assistant`}} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                            Go
                          </Button>
                        }                                   
                      </DialogActions>
                    </Dialog>
                  }                  
                  {/* {timer <= 0 && 
                    <Dialog open={timer <= 0}>
                      <DialogTitle>Nice Work!</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Thank you so much for trying the experience. We will send you a recording of your experience and a scorecard to your email.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Email Address"
                          type="email"
                          fullWidth
                          onChange={handleEmailChange}
                          variant="standard"
                        />                        
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleJoinWaitlist}>
                          Enter Email
                        </Button>
                      </DialogActions>
                    </Dialog>
                  } */}
              </Grid>              
          </Container>
          {scorecardModalOpen && (
            <Dialog
              style={{
                background: 'black',
                color: 'white',
                border: '0.5px solid #5F6368',
                fontFamily: 'Mulish'                  
              }}                          
              open={scorecardModalOpen}
              onClose={() => setScorecardModalOpen(false)}
              aria-labelledby="scorecard-dialog-title"
              aria-describedby="scorecard-dialog-description"
              fullWidth
              maxWidth="md" // Changed from default to make the dialog wider
            >
              <DialogTitle id="scorecard-dialog-title" 
                style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                  
                }}                            
              >Interview Scorecard</DialogTitle>
              <DialogContent
                style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                  
                }}              
              >
                <DialogContentText id="scorecard-dialog-description">
                  Here's how you did in your interview.
                </DialogContentText>
                <List>
                  <TableContainer component={Paper} 
                    style={{
                      background: 'black',
                      color: 'white',
                      border: '0.5px solid #5F6368',
                      fontFamily: 'Mulish'                  
                    }}                                
                  >
                    <Table aria-label="scorecard table">
                      <TableHead style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'                               
                      }}>
                        <TableRow style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'                                 
                        }}>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish'}}>Signal</TableCell>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Feedback</TableCell>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Score</TableCell>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Discussed in Interview</TableCell>                          
                        </TableRow>
                      </TableHead>
                      <TableBody style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'                               
                      }}>
                        {interview.scorecard && interview.scorecard.length === 0 &&
                        <TableRow style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'                        
                        }}>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress style={{ color: 'white' }} />
                          </TableCell>
                        </TableRow>
                        }
                        {interview.scorecard && interview.scorecard.length > 0 &&
                          <>
                            {interview.scorecard.map((row) => (
                              <TableRow key={row.signal} style={{
                                background: 'black',
                                color: 'white',
                                border: '0.5px solid #5F6368',
                                fontFamily: 'Mulish'                                       
                              }}>
                                <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} component="th" scope="row">
                                  {row.signal}
                                </TableCell>
                                <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.feedback}</TableCell>
                                <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.asked_to_candidate === false ? <>-</> : row.score > 7 ? 'Yes' : 'No'}</TableCell>
                                <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{String(row.asked_to_candidate).charAt(0).toUpperCase() + String(row.asked_to_candidate).slice(1)}</TableCell>                                
                              </TableRow>
                            ))}                        
                          </>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </List>   
                {/* <br/><br/>
                <p style={{ color: 'white', fontFamily: 'Mulish', fontWeight: 600 }}>Join the waitlist to get your recording. </p>    
                <TextField
                  InputLabelProps={{
                    style: { color: 'white', fontFamily: 'Mulish' },
                  }}
                  inputProps={{
                    style: { color: 'white', fontFamily: 'Mulish' },
                  }}    
                  InputProps={{
                    style: { color: 'white', borderBottom: '1px solid white' },
                  }}                          
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Enter your email"
                  type="email"
                  fullWidth
                  onChange={handleEmailChange}
                  variant="standard"
                />                                */}
              </DialogContent>
              {/* <DialogActions
                style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                  
                }}              
              >
                <Button style={{ color: 'white', fontFamily: 'Mulish'}} onClick={handleJoinWaitlist}>
                  Submit
                </Button>    
              </DialogActions>  */}
            </Dialog>
          )}
          {firstMobileScreenDone && isMobile &&
              <Grid item container xs={12} sm={12} style={{ padding: 10, margin: '0 auto', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Instructions</p>
                <br/><br/><br/>
                {renderContext()}                                     
              </Grid>
          }         
          {!firstMobileScreenDone && isMobile && 
            <Grid item container xs={12} sm={12} style={{ padding: 10, margin: '0 auto',borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ marginBottom: 80, border: '0.5px solid #5F6368', borderRadius: 10 }}>
                <iframe width="290px" height="203px" src="https://www.youtube.com/embed/MzvYtFLs9Tc?si=hVH8J-pIEztrLzOh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                   
              </div>
              <button style={{ color: 'black', background: '#19E539', border: '0 none', borderRadius: 25, fontFamily: 'Mulish', fontSize: 16, width: '80%', height: 48 }} onClick={() => {setFirstMobileScreenDone(true)}}>
                Start Interview
              </button>
              <br/>
              <br/>      
              <br/>
              <br/>      
              {/* <button style={{ color: 'white', background: 'black', border: '1px solid white', borderRadius: 25, fontFamily: 'Mulish', fontSize: 16, width: '80%', height: 48 }} onClick={handleOpenWaitlist}>
                Join Waitlist
              </button>                    */}
            </Grid>    
          }    
          <Container>
            <Snackbar
              open={subscriptionSuccessSnackOpen}
              autoHideDuration={5000}
              onClose={handleCloseSubscriptionFailSnack}>
                <Alert onClose={handleCloseFailSnack} severity="error" sx={{ width: '100%' }}>
                  Error: e-mail already used or invalid format.
                </Alert>
              </Snackbar>
            </Container>
        </>
    );
};

export default App;
